import { transformTcfSrc } from ".";
/**
 * Quote:
 *
 * > When a creative is rendered, it may contain a number of pixels under `<img>` tags. For example,
 * > `<img src="http://vendor-a.com/key1=val1&key2=val2">` which fires an HTTP GET request from the browser to Vendor A’s domain.
 *
 * So, it automatically transforms all links to GDPR compliant URLs. This also covers affiliate links, e.g. Awin through
 * the configured domains array in their published device disclosure.
 *
 * @see https://wiki.awin.com/index.php/Consent_Frameworks_for_Publishers
 * @see https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/af2930b79f0d8d771c5b526ebf4f9c0f4ed96f53/TCFv2/Vendor%20Device%20Storage%20%26%20Operational%20Disclosures.md#domains-array
 * @see https://git.io/JtmZn
 * @see https://git.io/JtmZO
 */ function transformTcfAdTags(tcfStringForVendors, gvl) {
    const nodes = [
        ...document.querySelectorAll('[src*="gdpr=${GDPR"]'),
        ...document.querySelectorAll('[src*="gdpr_consent=${GDPR"]'),
        ...document.querySelectorAll('[href*="gdpr=${GDPR"]'),
        ...document.querySelectorAll('[href*="gdpr_consent=${GDPR"]')
    ]// Unique nodes
    .filter((value, index, self)=>self.indexOf(value) === index);
    for (const node of nodes){
        const useAttr = node.hasAttribute("src") ? "src" : "href";
        node.setAttribute(useAttr, transformTcfSrc(node.getAttribute(useAttr), tcfStringForVendors, gvl));
    }
}
export { transformTcfAdTags };
